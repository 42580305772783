import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import profile from "../../components/common/Profile"
import FrontContainer from "../../components/member/FrontContainer"

export default () => {
  const Signup = profile("signup")
  const pageSlug = "signup"
  const pageTitle = "新規メンバー登録｜箕澤屋クラウド"

  const data = useStaticQuery(graphql`
    query {
      coverImage: file(relativePath: { eq: "member/cover/bg_signup.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const coverImage = data.coverImage.childImageSharp.fluid

  return (
    <FrontContainer
      pageSlug={pageSlug}
      pageTitle={pageTitle}
      coverImage={coverImage}
    >
      <Signup />
    </FrontContainer>
  )
}
